import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import './styles.css';
import theme from './theme';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { SttTranslateHook } from '@stt-componentes/core';
import Home from './paginas';
import { BrowserRouter, Routes, Route} from 'react-router-dom';


const App = () => {
    return (
        <div style={{height: '100%'}}>
            <NotificationContainer />
            <ThemeProvider theme={theme}>
                <BrowserRouter basename={`/${global.gConfig.basename}`}>
                    <SttTranslateHook.I18nContextProvider modulo={'valida'}>
                        <Routes>
                            <Route path="*" element={<Home/>} />
                        </Routes>
                    </SttTranslateHook.I18nContextProvider>
                </BrowserRouter>
                
            </ThemeProvider>
        </div>
    );
}

export default App;