import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SttButton, SttGrid, SttTranslateHook } from '@stt-componentes/core';
import { useSignals } from '@preact/signals-react/runtime';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    iframeContainer: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '20px',
        '@media (max-width: 992px)': {
            width: '100%'
        },
    },
    iframe: {
        width: '100%',
        height: '800px',
        border: 'none',
    },
    botaoVoltar: {
        marginBottom: theme.spacing(3)
    }
}));

const Iframe = ({ contexto, url }) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    const voltar = () => {
        url.value = null;
        navigate(`/${contexto}`, { replace: true });
    };

    return (
        <SttGrid container spacing={3} className={classes.iframeContainer}>
            <SttGrid item xs={12}>
                <iframe className={classes.iframe} src={url.value} />
            </SttGrid>
            <SttGrid item xs={12}>
                <SttButton
                    className={classes.botaoVoltar}
                    onClick={voltar}
                    color="primary"
                    variant="contained"
                    nomarginleft
                >
                    {strings.voltar}
                </SttButton>
            </SttGrid>
        </SttGrid>
    );
};

export default Iframe;
