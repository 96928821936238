import React, { useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttHeading,
    SttButton,
    SttTranslateHook,
    SttInput,
    SttNumberInput,
    SttGrid
} from '@stt-componentes/core';
import { useSignals } from '@preact/signals-react/runtime';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { CAMPOS } from '../constantes';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2)
    },
    titulo: {
        margin: theme.spacing(2)
    },
    formColumn: {
        border: '2px solid',
        borderColor: '#D9D9D9',
        padding: theme.spacing(9),
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));

const validationSchema = (strings) => {
    return yup.object().shape({
        [CAMPOS.IDENTIFICADOR]: yup
            .number()
            .nullable()
            .required(strings.campoObrigatorio),
        [CAMPOS.CODIGO]: yup
            .string()
            .nullable()
            .max(44, strings.tamanhoCodigo)
            .min(44, strings.tamanhoCodigo)
            .required(strings.campoObrigatorio)
    });
};

const Form = ({ contexto, formRef }) => {

    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={{
                    [CAMPOS.IDENTIFICADOR]: '',
                    [CAMPOS.CODIGO]: ''
                }}
                validationSchema={schema}
                onSubmit={(dados) => {
                    navigate(`/${contexto}?identificador=${dados[CAMPOS.IDENTIFICADOR]}&codigo=${encodeURIComponent(dados[CAMPOS.CODIGO])}`);
                }}
            >
                {({ isSubmitting, handleSubmit }) => {
                    return (
                        <form
                            onSubmit={handleSubmit}
                            noValidate
                            className={classes.formColumn}
                        >
                            <SttHeading variant="h2" color="primary" className={classes.titulo}>
                                {strings.consultaAutenticidade.replace('%1', contexto)}
                            </SttHeading>
                            <SttGrid>
                                <Field name={CAMPOS.IDENTIFICADOR}>
                                    {({ field, meta }) => (
                                        <SttNumberInput
                                            label={strings.identificador}
                                            error={
                                                meta.touched && meta.error
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                meta.touched && meta.error
                                                    ? meta.error
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                </Field>
                                <Field name={CAMPOS.CODIGO}>
                                    {({ field, meta }) => (
                                        <SttInput
                                            label={strings.codigo}
                                            error={
                                                meta.touched && meta.error
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                meta.touched && meta.error
                                                    ? meta.error
                                                    : undefined
                                            }
                                            inputProps={{ maxLength: 44 }}
                                            {...field}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                            <SttButton
                                className={classes.button}
                                type="submit"
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                            >
                                {strings.verificar}
                            </SttButton>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};

export default Form;
