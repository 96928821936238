import React, { useRef, useEffect, useContext } from 'react';
import { SttTranslateHook } from '@stt-componentes/core';
import axios from 'axios';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import alerta from '../../signals/alerta';
import loading from '../../signals/carregando';
import { useLocation } from 'react-router-dom';
import Form from '../../componentes/form';
import Iframe from '../../componentes/iframe';
import { CAMPOS, CONTEXTO } from '../../constantes';

const Exame = () => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const url = useSignal(null);
    const formRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const identificador = params.get(CAMPOS.IDENTIFICADOR);
        const codigo = params.get(CAMPOS.CODIGO);

        if (identificador && codigo) {
            loading.value = { ...loading.value, open: true };
            axios
                .get(`${global.gConfig.url_base_valida}/exame`, {
                    params: {
                        identificador: identificador,
                        codigo: codigo
                    }
                })
                .then((response) => {
                    if (response.data) {
                        url.value = response.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alerta.value = {
                        open: true,
                        title: strings.erro,
                        message: strings.documentoNaoEncontrado,
                        type: 'error',
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            }
                        ],
                        onClose: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    };
                }).finally(() => {
                    loading.value = { ...loading.value, open: false };
                    formRef.current?.setSubmitting(false);
                });
        }
    }, [location]);

    return (
        <>
            {
                url.value === null ? (
                    <Form contexto={CONTEXTO.EXAME} formRef={formRef} />
                ) : (
                    <Iframe contexto={CONTEXTO.EXAME} url={url} />
                )
            }
        </>
    );
};

export default Exame;
